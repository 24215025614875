import { SagaIterator } from "redux-saga";
import { takeLatest, takeEvery } from "redux-saga/effects";
import * as actions from "../actions";
import * as user from "./user";
import * as users from "./users";
import * as isv from "./isv";

/**
 * Root saga that connect sagas with actions.
 */
export default function* rootSaga(): SagaIterator {
  yield takeLatest(actions.signInUser, user.signIn);
  yield takeLatest(actions.fetchProfile, user.getProfile);
  yield takeLatest(actions.resetPassword, user.resetPasswordSaga);
  yield takeLatest(actions.forgotPassword, user.forgotPasswordSaga);
  yield takeLatest(actions.verifyMyAccount, user.verifyAccountSaga);
  yield takeLatest(actions.updateProfile, user.updateProfile);
  yield takeLatest(actions.fetchUsers, users.getAllUsers);
  yield takeLatest(actions.createLoginUrl, users.createLoginUrl);
  yield takeLatest(actions.fetchUserDetails, users.getUserDetails);
  yield takeLatest(actions.fetchAllPhoneNumbersDetails, users.getAllPhoneNumbers);
  yield takeLatest(actions.fetchUserBills, users.getUserBills);
  yield takeLatest(actions.changeUserPassword, users.changeUserPassword);
  yield takeLatest(actions.getCardDetails, users.getNewCardAPI);
  yield takeEvery(actions.getCredits, users.getCreditsAPI);
  yield takeEvery(actions.getAllPlans, users.getAllPlansAPI);
  yield takeEvery(actions.updatePlan, users.updatePlanAPI);
  yield takeEvery(actions.getLastInvoiceAction, users.getLastInvoiceAPI);
  yield takeEvery(actions.changeExpiryDate, users.changeExpiryDateAPI);
  yield takeEvery(actions.resetUsersPassword, users.forgotPasswordSaga);
  yield takeEvery(actions.fetchAllBills, users.getBills);
  yield takeEvery(actions.runCycleForAllUsers, users.runSubscriptionCycle);
  yield takeEvery(actions.finalizePayment, users.runPaymentCycle);
  yield takeEvery(actions.voidBill, users.voidPayment);

  yield takeEvery(actions.fetchBasicInformation, isv.getBasicInformationData);
  yield takeEvery(
    actions.fetchCampaignInformation,
    isv.getCampaignInformationData
  );
  yield takeEvery(
    actions.fetchTollfreeInformation,
    isv.getTollfreeInformationData
  );
  yield takeEvery(actions.getHostedNumberData, users.getHostedNumber);
  yield takeEvery(actions.changeUserStatus, users.changeUserStatus);
  yield takeEvery(actions.deleteNumberSaga, users.deleteNumber);
  yield takeEvery(actions.suspendUserAccount, users.suspendUserAccount);
  yield takeEvery(actions.fetchAdhocCharges, users.getUserAdhocCharge);
  yield takeEvery(actions.createAdhocCharges, users.createUserAdhocCharge);
  yield takeEvery(
    actions.checkAndVerifyHostedNumber,
    users.checkAndVerifyHostedNumber
  );
  yield takeEvery(actions.resetBrandInformation, users.resetBrandInformation);
  yield takeEvery(
    actions.resetCampaignInformation,
    users.resetCampaignInformation
  );
}
